import type { MaybeRef } from 'vue'
import { SHOULD_USE_GLOBAL_BOUND_KEY } from '@autobid/ui/constants/SHOULD_USE_GLOBAL_BOUND_KEY'

/**
 * useSkipGlobalErrorBoundary is a composable that disables the global error boundary that is used in `App.vue`
 *
 */
export function useSkipGlobalErrorBoundary(enabled: MaybeRef<boolean>) {
  const shouldUse: Ref<boolean> | undefined = inject(
    SHOULD_USE_GLOBAL_BOUND_KEY,
    undefined
  )

  onMounted(() => {
    if (!unref(enabled)) {
      return
    }
    shouldUse.value = false
  })
  onUnmounted(() => {
    if (!unref(enabled)) {
      return
    }
    shouldUse.value = true
  })
}
